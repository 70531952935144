@import '../../styles/common';
@import '../../styles/variables'; 

.content-table-menu-content{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'Crimson-Pro';
    text-transform: uppercase;
    font-size: calc(18px * $font-size-increase-factor);
    color: white;
}

.content-table-page{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: $default-orange;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
    

    .title{
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: white;
        align-items: center;
        margin-bottom: 30px;
        text-transform: uppercase;
        font-family: 'CrimsonPro-Regular';
        font-size: calc(20px * $font-size-increase-factor);

        svg{
            margin-bottom: -2px;
        }
        

        .line{
            position: relative;
            background: $uniform-fade-in-fade-out-white;
            height: 2px;
            width: 250px;
            margin-top: 20px;
    
            img{
                position: absolute;
                left: calc(50% - 7.5px);
                width: 15px;
            }
        }
    }

    .sections{
        text-transform: uppercase;
        font-family: 'Oswald-Bold';

        .section-title{
            color: black;
            font-size: calc(20px * $font-size-increase-factor);
            text-decoration: solid underline rgba(0, 0, 0, 0.50) 2px;
            letter-spacing: 0px;
            text-underline-offset:2px;

            margin-bottom: 20px;
            display: flex;
            cursor: pointer;
        }

        .section-item{
            width: 100%;
            color: white;
            font-size: calc(16px * $font-size-increase-factor);
            text-decoration: solid underline rgba(255, 255, 255, 0.5) 1.5px;
            text-underline-offset:2px;
            letter-spacing: 0.5px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            img{
                width: 40px;
                height: 40px;
                margin-right: 10px;
            }
            div{
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }

        .section-separator{
            height: 2px;
            width: 80%;
            background: $strong-fade-in-fade-out-white;
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

    .button-container{
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;

        a{
            text-decoration: none;
            color: white;
            margin-top: 20px;
            font-size: 20px;
        }
    }
}