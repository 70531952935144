@import '../../styles/variables';


.orange-text{
    color: $default-orange;
}

.orange-bg{
    background-color: $default-orange;
}

.line{
    background-color: $default-orange;
    height: 1px;
    width: 100%;
}

.ftOswaldSb{
    font-family: "Oswald-Semibold"
}