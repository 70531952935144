@import './variables';

.text-orange{
    color: $default_orange;
}


.chapter-select{
    min-width: 300px;
    min-height: 50px;
    border: $default_orange 2px solid;
    border-radius: 5px;
    background-color: black;
    color: white;
    font-family: 'CrimsonPro';
    padding-left: 15px;
    background-image: url("../../public/images/svgs/down-arrow.svg");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.default-button{
    padding: 10px;
    border:$default_orange 2px solid;
    font-family: 'Oswald-Regular';
    background-color: $default_orange;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
}

.open-close-container{
    position:absolute;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 20px;
    min-height: 64px;
    z-index: 3;

    .open-close-button{
        cursor: pointer;
        color: white;
    }
}



.force-font-size{
    p, span{
        font-size: 16px !important;
    }
}

.force-font-size-25{
    p, span{
        font-size: 25px !important;
    }
}

.force-font-size-30{
    p, span{
        font-size: 30px !important;
    }
}

.force-icon-font-size{
    p, span{
        font-size: 40px !important;
    }
}

.force-title-font-size{
    p, span{
        font-size: 50px !important;
    }
}

.force-font-color div{
    color:black !important;
}

.force-font-color p{
    color:black !important;
}

.force-font-color span{
    color:black !important;
}

.full-line{
    width: 100%;
    max-width: 300px;
    height: 2px;
    background: $full-to-fade-out-gray;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
}

.back-to-district-button{
    -webkit-border-radius: 8;
    -moz-border-radius: 8;
    border-radius: 8px;
    font-family: Georgia;
    color: $default-orange;
    font-size: 18px;
    padding: 10px 40px 10px 40px;
    border: solid $default-orange 2px;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'Oswald-Bold';
}