@tailwind base;
@tailwind components;
@tailwind utilities;

html{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    background-color: black;
}

body {
    margin: 0px;
    padding: 0px;
    height: 100%;
    background-color: black;
}

body{
    position: relative;
    max-width: 645px;
    margin-left: auto;
    margin-right: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    box-sizing: border-box;
}

p {
    margin: 0px;
    padding: 0px;
}

#root{
    width: 100%;
    height: 100%;
  }


::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.safearea {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
}

.background-image {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../../public/images/main-background.webp');
}

.background-overlay {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
}

.background-overlay-faded {
    height: 100%;
    width: 100%;
    /* background-color: rgba(0, 0, 0, 0.8); */
    background: linear-gradient(180deg, rgba(255,255,255,0) 7%, rgba(0,0,0,1) 62%);
}

.full-to-fade-gradient {
    background:linear-gradient(90deg, rgba(187,138,67,1) 0%, rgba(187,138,67,1) 70%, rgba(255,255,255,1) 90%);
}

.full-to-fade-gradient-black {
    background:linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 70%, rgba(0,0,0,0) 90%);
}


.fade-to-fade-gradient {
    background: linear-gradient(90deg, rgba(187,138,67,0) 10%, rgba(187,138,67,1) 30%, rgba(187,138,67,1) 70%, rgba(255,255,255,1) 90%);
}

.fade-to-fade-gradient-black {
    background: linear-gradient(90deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 70%, rgba(0,0,0,0) 90%);
}

.fade-to-fade-gold {
    background: linear-gradient(90deg, rgba(187,138,67,0) 10%, rgba(187,138,67,1) 30%, rgba(187,138,67,1) 70%, rgba(187,138,67,0) 90%);
}

.tg {
    border-collapse: collapse;
    border-spacing: 0;
}

.tg td {
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    padding: 5px;
    background-color: rgba(187,138,67,1);
}

.tg .content{
    background-color: #f1eae0;
}

.tg .right-border{
    border-right-color: black;
}

.tg th {
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    padding: 5px;
    background-color: #4d4d4f;
}

/* Page ChapterIntro1NpcColumn - link */
.parent-div a.linkCustom p:first-child span{
    color: rgba(187,138,67,1) !important;
}

.textForceColorCustom p{
    color: rgba(187,138,67,1) !important;
}

.textForceColorFontCustom p{
    color: rgba(187,138,67,1) !important;
    font-family: "Oswald-Semibold" !important;
}

.colorLink a p span {
    color: rgba(187,138,67,1) !important;
}

/* Pour interlude 3 page du milieu */
.custom-font-size div p,
.custom-font-size div p span{
    font-size: 16px !important;
    text-align: left !important;

}

/* ACTION PAGE INTRO */

/* intro texte Action chap8 & chap20 */
.text-underline-none p:first-of-type{
    text-decoration: none;
}

.text-underline-none p:first-of-type span:not(.underline) {
    font-family: "Oswald-Regular" !important ;
}

/* prologue gangrel Action */
.text-font-normal span:not(.underline){
    font-family: "Oswald-Regular" !important ;
}

/* chap 19 intro Action */
.first-text-span p:first-of-type{
    color: rgb(241, 228, 215) !important;
    font-family: "Oswald-Regular" !important ;
    font-weight: normal;
}

.first-text-span p:nth-of-type(2){
    text-decoration: underline;
}

.textActionColor p span{
    color: rgba(187,138,67,1) !important;
}

.underlinePlusColor p span{
    color: rgba(187,138,67,1) !important;
    text-decoration: underline;
    font-family: "Oswald-Semibold" !important;
    font-weight: bold;
}

/* FIN ACTION PAGE INTRO */


.custom-padding p{
    padding-top: 10px;
    padding-bottom: 10px;
}

.textForceColor2 p span{
    color: rgba(187,138,67,1) !important;
}

.custom-font-size div{
    width: 100% !important;
}

/* Pour page introduction chapitre 24, suppr read 2 */

.customChapToreadorFlaw p:nth-child(2){
    display: none;
}

/* Page Chapter Intro2Npnc Column - npc titre  */

.customTitleNpc p {
    font-family: "Oswald-Semibold" !important;
    font-size: 25px !important;

}

.customTitleNpc span {
    font-family: "Oswald-Regular" !important;
    font-size: 25px !important;

}

.textCustom p {
    margin-bottom: 10px !important; 
}

.lineGreyCustom{
    text-decoration: underline;
    color: rgb(109, 110, 113);
    text-underline-offset: 3px;
}

.paddingHexGrid {
    padding-left: 0.5rem;
}

@media (max-width: 500px) {
    .paddingHexGrid {
        padding-left: 0rem; 
    }
}
