@import '../../styles/common';
@import '../../styles/variables';

.web-navigation {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 10%;
    top: 0;
    left: 0;

    .left,
    .right {
        background-color: transparent;
        width: 100px;
        height: 100%;
        transition: all .2s;

        &:active {
            background-color: black;
            opacity: 0.7;
        }
    }
}

.chapter-page-content {
    height: 100%;
}

.card-wrapper {

    height: 100%;
    width: 100%;
    max-width: $max-width;
    display: flex;

    .navigation{
        width: 20px;
        background-color: transparent;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    

    .chapter-page {
        width: 100%;
        height: 100%;
        background-color: white;
        overflow-x: hidden;

        .chapter-container {
            max-width: 645px;
            height: calc(100% - 70px - (env(safe-area-inset-bottom) / 2));
            flex-grow: 1;
            overflow-y: auto;
            margin-left: auto;
            margin-right: auto;
        }

        .content-table-menu {
            max-width: 645px;
            margin-left: auto;
            margin-right: auto;
            height: calc(70px + (env(safe-area-inset-bottom) / 2));
            background-color: $default-orange;
            position: relative;

            div {
            }
        }
    }
}