@import '../styles/variables';
@import '../styles/common';

.button-container{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    button{
        width: 300px;
    }
}

.bubble-container{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    width: 300px;
    margin-top: 30px;
    flex-direction: column;
    
    .item{
        display: flex;
        align-items: center;
        color: $default_orange;
        text-transform: uppercase;
        font-size: calc(20px * $font-size-increase-factor);
        font-family: 'Oswald-Bold';
        letter-spacing: 1px;
        text-decoration: underline 1px;
        text-decoration-line: underline 1px;
        text-underline-offset: 4px;
        -webkit-text-decoration-line: underline 1px red;
        -webkit-text-underline-offset: 4px;
        margin-bottom: 20px;
        cursor: pointer;
    
        img{
            width: 70px;
            height: 70px;
            margin-right: 20px;
            border-radius: 50%;
            border: solid $default_orange 1px;
        }
    }
}


.home{
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    flex-direction: column;
    align-items: center;
    

    .header-image{
        margin-top: 100px;
        max-width: 400px;
        width: 100%;
    }

    .app-description{
        max-width: 80%;
        margin-top: 30px;
        margin-bottom: 30px;
        text-align: center;
        font-family: 'CrimsonPro';
        color: $default_gray;
        font-style: italic;
        font-size: 16px;
    }

    
}


.modal-menu{
    
  box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.95);
    top: 0;
    left: 0;
    overflow-y: auto;


    .image-container{
        margin-top: 80px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 100%;
            max-width: 400px;
        }
    }

    .select-container{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

    .menu-items{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .item, .item-gray{
            width: 300px;
            color: $default_orange;
            font-family: 'Oswald-Regular';
            text-transform: uppercase;
            text-decoration: underline;
            font-size: calc( 20px * $font-size-increase-factor);
            margin-top: 20px;
            cursor: pointer;
        }

        .item-gray{
            color: #7A7367;
        }
    }

}