@import '../../styles/common';
@import '../../styles/variables';

.inventory2-page {
    color: white;

    .top-space{
        height: 300px;
        width: 10px;
    }

    h1{
        font-size: 50px;
        text-transform: uppercase;
        
    }

    .line{
        width: 80%;
        margin-left:auto;
        margin-right:auto;
        height: 2px;
        background: linear-gradient(90deg, rgba(187,138,67,0) 0%, rgba(187,138,67,1) 15%, rgba(187,138,67,1) 85%, rgba(187,138,67,0) 100%);
    }

    .top{
        position: relative;
        
        img{
            position: absolute;
            left: calc(50% - 7px);
            width: 15px;
            margin-top: -17px;
        }
    }

    .bottom{
        img{
            position: absolute;
            left: calc(50% - 7px);
            width: 15px;
            margin-bottom: 2px;
        }
    }

    h1{
        font-size: 40px;
        font-family: 'Oswald-Regular';
        padding: 10px 0px;
        margin-left:auto;
        margin-right:auto;
        text-align: center;
    }

    .description{
        margin-top: 30px;
        font-family: 'CrimsoPro';
        font-size: 15px;
        max-width: 80%;
        text-align: center;
        text-transform: none;
        margin-left:auto;
        margin-right:auto;
    }

    .inventory-list-container{
        width: 100%;
        margin-bottom: 30px;
        
        .item{
            display: flex;
            width: 100%;
            padding-left: 10%;
            padding-right: 10%;
        }

        p{
            padding-right: 2px;
        }

        .chapter-title{  
            font-size: 20px;
            color: $default_orange;
            font-family: 'Oswald-Regular';
            text-align: left;
            padding: 20px 0px;
            margin-top: 10px;
            border-top: $default_orange 2px solid;
            border-bottom: $default_orange 2px solid;
            background: linear-gradient(90deg, rgba(187,138,67,0.4) 0%, rgba(187,138,67,0) 70%);
        }

        .chapter-item{
            font-family: "CrimsonPro";
            font-size: 15px;
            justify-content: space-between;
            padding: 10px 0px 5px 0px;
            padding-left: 10%;
            padding-right: 10%;

            p:last-child{
                text-wrap: nowrap;
            }
        }

        .white-gradient-line{
            width: 100%;
            height: 2px;
            margin: 0px 20px;
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6469817546583851) 7%, rgba(255,255,255,0.8457395186335404) 28%, rgba(255,255,255,0.429590450310559) 57%, rgba(255,255,255,0) 95%);
        }

    }

    .button-container{
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;

        a{
            text-decoration: none;
            color: white;
            margin-bottom: 30px;
            font-size: 20px;
        }
    }
}