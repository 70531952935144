$default_orange: #bb8a43;
$default_gray: #949494;
$white-rgba:rgba(255,255,255,1);
$max-width: 645px;
$font-size-increase-factor :1.1;

$uniform-fade-in-fade-out-white: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 20%, rgba(255,255,255,0.8) 80%, rgba(255,255,255,0) 100%);
$strong-fade-in-fade-out-white: linear-gradient(90deg, rgba(255,255,255,0.60) 0%, rgba(255,255,255,0.60) 70%, rgba(255,255,255,0.1) 100%);;
$strong-fade-in-fade-out-orange: linear-gradient(90deg, rgba(187,138,67,0) 0%, rgba(187,138,67,1) 15%, rgba(187,138,67,1) 85%, rgba(187,138,67,0) 100%);
$full-to-fade-out-gray: linear-gradient(90deg, rgba(125,117,106,1) 0%, rgba(125,117,106,1) 70%, rgba(125,117,106,0) 90%, rgba(125,117,106,0) 100%);


.gray{
    color: gray;
}